export enum Theme {
  ToolsDomainNameGenerator = 'tools/domain-name-generator',
  StoreLogin = 'store-login',
  Enterprise = 'enterprise',
  ONE_MBB = '1mbb',
  Capital2024 = 'capital-2024',
  EnterpriseCommerce = 'enterprise-commerce',
  FutureOfCommerce = 'future-of-commerce',
  News = 'news',
  Oberlo = 'oberlo',
  Blog = 'blog',
  Foundation = 'foundation',
  BrochureV2 = 'brochureV2',
  CommerceComponents = 'commerceComponents',
  EditionsWinter2023 = 'editionsWinter2023',
  EditionsWinter2024 = 'editionsWinter2024',
  EditionsJune2022 = 'editionsJune2022',
  EditionsSummer2022 = 'editionsSummer2022',
  EditionsSummer2024 = 'editionsSummer2024',
  EditionsAll = 'editionsAll',
  EditionsS24Growth = 'editionsS24Growth',
  Pos = 'pos',
  Careers = 'careers',
  Plus = 'plus',
  Online = 'online',
  Partners = 'partners',
  Home = 'home',
  HomeNext = 'homeNext',
  Audiences = 'audiences',
  FinancialServices = 'financialServices',
  CompareSalesforce = 'compareSalesforce',
  CompareLightspeed = 'compareLightspeed',
  CompareSquare = 'compareSquare',
  CompareEtsy = 'compareEtsy',
}

export enum ApprovedCookie {
  MultiTrackToken = '_shopify_y',
  SessionToken = '_shopify_s',
  // The assignment cookie isn't the whole key as there is a hash prefix
  ExperimentAssignmentCookie = '_assignment',
  GoogleAnalyticsId = '_ga',
  Source = 'source',
  EnableRedesign = 'enable_redesign',
  DisableDebugBar = 'disable_debug_bar',
  DebugDomain = 'debug_domain',
  EnableDevmode = 'enable_devmode',
  CookieNoticeAcknolwedged = 'eu_cookies_acknowledged',
  PrivacySignal = 'privacy_signal',
  LastShop = 'last_shop',
  IsShopifyMerchant = 'is_shopify_merchant',
  EnableStaticPageHandler = 'enable_static_page_handler',
  LoggedIn = 'logged_in',
}

export enum RoutingBypassValue {
  Marketing = 'Route to Marketing Nurture',
  Owner = 'Route to Owner',
  Sales = 'Route to Sales',
  Standard = 'Standard Routing',
}

export enum FormSourceTypes {
  Contact = 'Contact Us',
  Marketing = 'Marketing Campaign',
  Lead = 'Lead List',
  Content = 'Content',
  Referral = 'Partner Referral',
  Demo = 'Demo',
  Webinar = 'Webinar',
  Plus = 'Plus',
  PartnerEvent = 'Partner Event',
  Event = 'Event',
  MarketingCampaign = 'Marketing Campaign',
  EventSalesMeeting = 'Event - Sales Meeting',
  EventBoothScan = 'Event - Booth Scan',
}

export enum FormTypes {
  Contact = 'contact',
  Event = 'event',
  Resource = 'resource',
  Webinar = 'webinar',
  Referral = 'referral',
  Subscribe = 'subscribe',
  Demo = 'demo',
}

export enum DirectMarketingCampaignStatuses {
  Attended = 'Attended',
  AttendedOnDemand = 'Attended - On demand',
  Downloaded = 'Downloaded',
  Registered = 'Registered',
  FilledOutForm = 'Filled-Out Form',
}

export enum ProductInterests {
  Plus = 'Plus',
  PosPro = 'POS Pro',
}

export enum ResourceTypes {
  Demo = 'demo',
  Guide = 'guide',
  Newsletter = 'newsletter',
  Program = 'program',
  Webinar = 'webinar',
  Event = 'event',
}

export enum Key {
  ArrowDown = 'ArrowDown',
  ArrowUp = 'ArrowUp',
  ArrowLeft = 'ArrowLeft',
  ArrowRight = 'ArrowRight',
  Backspace = 'Backspace',
  End = 'End',
  Enter = 'Enter',
  Escape = 'Escape',
  Home = 'GoHome',
  Space = ' ',
  OldSpace = 'Spacebar',
}

export enum ScrollDirection {
  Down = 'down',
  Up = 'up',
}

export enum ComplianceZone {
  Gdpr = 'gdpr',
  Ccpa = 'ccpa',
  None = 'none',
}

export enum CookieNoticeBannerVariant {
  PassiveConsent = 'passive_consent',
  ActiveConsent = 'active_consent',
}

export enum UrlParam {
  MultiTrackToken = '_y',
  SessionToken = '_s',
  PageViewToken = '_p',
  SignupTypes = 'signup_types[]',
  SignupPage = 'signup_page',
  SignupRef = 'ref',
  Login = 'login',
  PromoCode = 'promotional_program_code_promo_code',
  GeoCountryCode = 'geo_country_code',
  AffiliateIRClickId = 'irclickid',
  AffiliateIRGWC = 'irgwc',
  AffiliatePartnerId = 'partner',
  AffiliatePartnerExcluded = 'affpt',
  ExperimentVariationId = 'experiment_variation_id',
  CanonicalUrl = 'canonical_url',
  AshbyJid = 'ashby_jid',
}

/** Available header background color schemes. */
export enum HeaderBackgroundColorScheme {
  Black = 'black',
  TransparentDark = 'transparent_dark',
  TransparentLight = 'transparent_light',
  White = 'white',
}

export enum PricingSliderEnum {
  Monthly = 'monthly',
  Yearly = 'yearly',
  None = 'none',
}

/** Horizontal position of content within a section. */
export enum AlignmentEnum {
  Bottom = 'bottom',
  Center = 'center',
  Left = 'left',
  Right = 'right',
  Top = 'top',
}

/** Horizontal position of button within  */
export enum ButtonAlignmentEnum {
  Center = 'center',
  Left = 'left',
  Right = 'right',
}

/** Available color schemes. */
export enum ColorSchemeEnum {
  Agave = 'agave',
  Aloe = 'aloe',
  Aloe30 = 'aloe_30',
  Banana = 'banana',
  Black = 'black',
  CyanLight = 'cyan_light',
  Dark = 'dark',
  DeepBlue = 'deep_blue',
  DragonFruit = 'dragon_fruit',
  Gray = 'gray',
  Kale = 'kale',
  Lavender = 'lavender',
  Light = 'light',
  Lime = 'lime',
  PosDark = 'pos_dark',
  PosLight = 'pos_light',
  PosWarmGray = 'pos_warm_gray',
  ShopifyGreen = 'shopify_green',
  ShopifyGreenGradient = 'shopify_green_gradient',
  ShopifyPurpleGradient = 'shopify_purple_gradient',
  ShopifyPlusGreen = 'shopify_plus_green',
  ShopifyGreenBlog = 'shopify_green_blog',
  Transparent = 'transparent',
  WarmGray = 'warm_gray',
  White = 'white',
}

/** Size of a button */
export enum ButtonSizeEnum {
  Large = 'large',
  Medium = 'medium',
  Small = 'small',
}

/** How to display links. */
export enum LinkDisplayEnum {
  Button = 'button',
  Link = 'link',
}

/** Vertical position of label within stats section. */
export enum LabelPositionEnum {
  Bottom = 'bottom',
  Top = 'top',
}

/** Select unordered list style */
export enum ListStyleEnum {
  Disc = 'disc',
  None = 'none',
}

/** Size of modal. */
export enum ModalSizeEnum {
  Full = 'full',
  Large = 'large',
  Small = 'small',
}

/** Adds context to SignupComponent */
export enum SignupFormTypeEnum {
  ButtonOnly = 'button_only',
  Disabled = 'disabled',
  DisclaimerOnly = 'disclaimer_only',
  NoEmail = 'no_email',
}

/** These slugs map to keys used in JSON files to identify visuals. They are not camelized in the schema. */
export enum VisualEnum {
  FindBrands = 'find_brands',
  GrowthDashboard = 'growth_dashboard',
  HomepageChachingNotifications = 'homepage_chaching_notifications',
  HomepageHeroCardFulfillment = 'homepage_hero_card_fulfillment',
  HomepageHeroCardOrder = 'homepage_hero_card_order',
  HomepageHeroCardSales = 'homepage_hero_card_sales',
  HomepageHeroCardStock = 'homepage_hero_card_stock',
  Linkpop = 'linkpop',
  ProductUpload = 'product_upload',
  SocialSelling = 'social_selling',
  WistiaVideo = 'wistia_video',
}

export enum IconEnum {
  Apps = 'apps',
  Arrow = 'arrow',
  Bag = 'bag',
  BlogPost = 'blog_post',
  Boost = 'boost',
  BuyOnline = 'buy_online',
  Champion = 'champion',
  Check = 'check',
  Chevron = 'chevron',
  Checkmark = 'checkmark',
  CheckmarkCircle = 'checkmark_circle',
  Close = 'close',
  Code = 'code',
  Collapse = 'collapse',
  CollectEmail = 'collect_email',
  ComputerTick = 'computer_tick',
  CreditCard = 'credit_card',
  CustomerExperience = 'customer_experience',
  CustomerSupport = 'customer_support',
  Design = 'design',
  Detect = 'detect',
  Devices = 'devices',
  DiagonalArrow = 'diagonal_arrow',
  Discount = 'discount',
  Domain = 'domain',
  DragAndDrop = 'drag_and_drop',
  Expand = 'expand',
  ExpandCollapse = 'expand_collapse',
  ExternalLinkArrow = 'external_link_arrow',
  Filters = 'filters',
  FlexibleShopping = 'flexible_shopping',
  FlexibleTime = 'flexible_time',
  Gift = 'gift',
  Global = 'global',
  GlobalCurrency = 'global_currency',
  Group = 'group',
  Guarantee = 'guarantee',
  Information = 'information',
  InventorySynced = 'inventory_synced',
  Legal = 'legal',
  Location = 'location',
  Mail = 'mail',
  Marketing = 'marketing',
  MaximumGauge = 'maximum_gauge',
  Messages = 'messages',
  Money = 'money',
  Next = 'next',
  OnlineStore = 'online_store',
  Packaging = 'packaging',
  Pause = 'pause',
  Phone = 'phone',
  PhoneTick = 'phone_tick',
  Play = 'play',
  PlayPause = 'play_pause',
  PosSwipe = 'pos_swipe',
  PosTap = 'pos_tap',
  PosTick = 'pos_tick',
  Previous = 'previous',
  Product = 'product',
  Quick = 'quick',
  Read = 'read',
  RealTime = 'real_time',
  Replay = 'replay',
  Report = 'report',
  Return = 'return',
  SalesChannels = 'sales_channels',
  SalesUp = 'sales_up',
  SaveTime = 'save_time',
  Search = 'search',
  Secure = 'secure',
  Seo = 'seo',
  Shipping = 'shipping',
  Social = 'social',
  Star = 'star',
  Stop = 'stop',
  Store = 'store',
  Success = 'success',
  Tools = 'tools',
  Unlimited = 'unlimited',
  Uptime = 'uptime',
  UserProfile = 'user_profile',
  Visible = 'visible',
  Wallet = 'wallet',
}

export enum ImageryCrop {
  Center = 'center',
  Top = 'top',
  Bottom = 'bottom',
  Left = 'left',
  Right = 'right',
  None = 'none',
}

export enum PlanName {
  // Note not `starter_2022`, so does not line up with technical system name
  Starter = 'starter',
  Starter_2022 = 'starter_2022',
  Basic = 'basic',
  Shopify = 'professional',
  Advanced = 'unlimited',
  Plus = 'plus',
  CommerceComponents = 'commerce_components',
  Retail = 'retail',
}

export enum Currency {
  USD = 'USD',
  INR = 'INR',
}

export enum CtaVariant {
  Custom = 'custom',
  Disabled = 'disabled',
  EmailSignup = 'email_signup',
  Signup = 'signup',
  WaitlistForm = 'waitlist_form',
}
